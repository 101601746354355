<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('menu_failed_login_attempts')}}</b-breadcrumb-item>
            </div>
        </div>


        <b-card
                no-body
                class="mb-0"
        >
            <b-card-header class="pb-50">
                <h5>{{ $t('menu_failed_login_attempts') }}</h5>
            </b-card-header>
            <div class="m-2">

                <b-row>

                    <b-col
                            cols="12"
                            md="5"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"

                    >

                        <v-select
                                v-model="tableData.perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                :searchable="false"
                                class="per-page-selector d-inline-block mx-50"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                    </b-col>

                    <b-col
                            cols="12"
                            md="5"
                            class="ml-auto d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <b-form-input
                                v-model="tableData.searchQuery"
                                type="search"
                                debounce="500"
                                class="d-inline-block mr-1 "
                                :placeholder="$t('label_search')+'...'"
                        />


                    </b-col>
                </b-row>

            </div>

            <!--***********************************************************TABLE*********************************************-->

            <div class="px-2 table-container-wrap">
                <!--sticky-header-->
                <b-table
                        ref="table"
                        class="position-relative transited-table"
                        :busy="isBusy"
                        no-border-collapse
                        :items="tableItems"
                        responsive
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"

                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>


                    <template #cell(userName)="data">
                        <span v-if="data.item.user_id">{{ data.item.userName +  ' (' + data.item.emailAddress + ')'}}</span>
                        <span v-else>--</span>
                    </template>

                    <template #cell(user)="data">
                        {{ data.item.user? data.item.user.name : ''}}
                    </template>

                    <template #cell(status)="data">
                        <!--<b-button size="sm" :variant="data.item.status == 1 ? 'outline-danger' : 'outline-success'">{{solveStatus(data.item.status)}}</b-button>-->
                        <span  :class="data.item.status == 1 ? 'text-danger' : 'text-success'">{{solveStatus(data.item.status)}}</span>

                    </template>

                    <template #cell(actions)="data">
                      <template v-if="['0','2'].includes(data.item.status)">

                          <b-button size="sm" variant="outline-danger" v-if="!data.item.user_id" @click="blockOrUnblockIp(data.item.ip,'block')"
                             :title="$t('action_block_ip')"
                          >{{$t('action_block_ip')}}</b-button>

                          <b-button size="sm"  v-else variant="outline-danger"  @click="blockOrUnblockUser(data.item.user_id,'block')"
                             :title="$t('action_block_user')"
                          >{{$t('action_block_user')}}</b-button>

                      </template>

                      <template v-else-if="data.item.status == 1">


                          <b-button size="sm"  v-if="!data.item.user_id" variant="outline-primary"  @click="blockOrUnblockIp(data.item.ip,'unblock')">
                              {{$t('action_unblock_ip')}}
                          </b-button>

                          <b-button size="sm" v-else variant="outline-primary"  @click="blockOrUnblockUser(data.item.user_id,'unblock')">
                              {{$t('action_unblock_user')}}
                          </b-button>


                      </template>
                    </template>


                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>


        </b-card>


    </div>
</template>

<script>
    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'


    export default {
        components: {
            BCard,

            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            vSelect,
            // processItemModal
        },

        // props: ['tableType'],

        data() {
            return {
                MODULE_PREFIX: 'failed_auth',

                // appConfig,

                columnsDefs: [

                    // {label: 'label_#', key: 'counter', sortable: false},
                    {label: 'label_date_and_time',  key: 'created_at',formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'unix');
                        }, sortable: false},
                    {label: 'label_user', key: 'userName', sortable: false},
                    {label: 'label_ip_address', key: 'ip', sortable: false},
                    {label: 'label_status', key: 'status', sortable: false},
                    {label: 'label_action', key: 'actions', sortable: false},
                ],

                editedItem: false,

                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 50, 100, 200],
                },

                // langSlots:[],
                // chosenRowEl: false
            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },

        },

        created() {

            this.refreshDataTable(true);
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/' + this.MODULE_PREFIX , {
                    params: {

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc ? true : null,
                        // mail_type: 'incoming',


                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },

            solveStatus(status){
                status = parseInt(status);
                    switch (status) {
                        case 0:
                            status = 'unblocked';
                            break;
                        case 1:
                            status = 'blocked';
                            break;
                        case 2:
                            status = 'unblocked';
                            break;
                        default:
                            status = 'undefined';
                    }

                    return this.$t('label_status_' + status);

            },
           // getBlockStatusLabel(status)
           //  {
           //      switch (status) {
           //          case 0:
           //              status = 'unblocked';
           //              break;
           //          case 1:
           //              status = 'blocked';
           //              break;
           //          case 2:
           //              status = 'unblocked';
           //              break;
           //          default:
           //              status = 'undefined';
           //      }
           //
           //      return this.$t('label_status_' + status);
           //  },
            blockOrUnblockIp(ip,action){
                let url = 'user/blockip?ip=';
                if(action == 'unblock') {
                    url = 'user/unblockip?ip=' ;
                }
                this.async('get', url + ip , {}, function (resp) {
                    this.refreshDataTable();
                });
            },
            blockOrUnblockUser(id,action){
                let url = 'user/block/';
                if(action == 'unblock') {
                    url = 'user/unblock/';
                }
                this.async('get', url  +id , {}, function (resp) {
                    this.refreshDataTable();
                });
            }
        },


    }
</script>

